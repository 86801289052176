import React from 'react';
import AuthContext from 'pages/Auth/auth.context';
import SearchContext from 'pages/Auth/search.context';
import CategoriesContext from 'contexts/categories.context';
// import 'antd/dist/antd.css';
import './assets/icomoon/style.css';
import './styles/main.scss';
import Root from 'pages/Root';
import './translations/i18n';
import './translations/translation';

function Bootstrap() {
  return (
    <AuthContext.Provider>
      <CategoriesContext.Provider>
        <SearchContext.Provider>
          <Root />
        </SearchContext.Provider>
      </CategoriesContext.Provider>
    </AuthContext.Provider>
  );
}

export default Bootstrap;
