import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Empty, Button } from 'antd';
import * as Sentry from '@sentry/browser';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_SENTRY_DSN.length
) {
  Sentry.init({
    debug: false,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_VERSION,
    environment: process.env.NODE_ENV,
  });
}
const toError = (error, componentStack) => {
  return `${error.toString()}\n\nThis is located at:${componentStack}`;
};

const { Title, Paragraph } = Typography;

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      info: null,
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    this.setState({ info });
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError, info, error } = this.state;
    const title = info ? info.componentStack : '';
    if (hasError) {
      return (
        <Row justify="space-around" align="middle">
          <Col align="middle" justify="center">
            <Title level={1}>Ohhh noo... Error</Title>
            <Title level={4} type="danger">
              Something went wrong.
            </Title>
            <Empty description="Try again, refresh page or inform us." />
            <Row gutter={24} style={{ marginTop: '24px' }}>
              <Col span={12} offset={6}>
                <Button
                  type="primary"
                  onClick={() =>
                    Sentry.showReportDialog({ eventId: this.state.eventId })
                  }>
                  Report feedback
                </Button>
                <Paragraph
                  style={{ marginTop: '24px' }}
                  ellipsis={{ rows: 3, expandable: true }}
                  code
                  copyable>
                  {toError(error, title)}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

export default ErrorBoundary;
