import React from 'react';
import { createContainer } from 'unstated-next';
import AuthContext from 'pages/Auth/auth.context';
import API_URL from 'services/API_URL';
import CargoContentButton from 'components/Common/CargoContentButton';

function useMaterial() {
  const { token } = AuthContext.useContainer();
  const language = localStorage.getItem('language') || 'pl';

  const previewExt = ['jpg', 'jpeg', 'png', 'pdf'];

  const getContentUrl = (url, type = 'display') => {
    if (url.trim() === '') {
      return '';
    }

    if (type !== 'download') {
      // eslint-disable-next-line no-param-reassign
      type = 'display';
    }

    return `${API_URL}/api/${language}/material/${type}/${url}?token=${token.token}`;
  };

  const getThumbnailUrl = url => {
    if (url.trim() === '') {
      return '';
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(url)) {
      return url;
    }

    return `${API_URL}/api/${language}/material/display/${url}/thumbnail?token=${token.token}`;
  };

  const getCargoContentButtons = (extension, url, downloadUrl) => {
    const avExt = ['jpg', 'jpeg', 'png', 'txt', 'mp4', 'avi', 'mp3', 'pdf'];

    if (extension === 'youtube') {
      return <CargoContentButton url={url} downloadUrl="" />;
    }

    if (avExt.indexOf(extension) >= 0) {
      return <CargoContentButton url={url} downloadUrl={downloadUrl} />;
    }

    return <CargoContentButton url="" downloadUrl={downloadUrl} />;
  };

  const hasPreview = extension => {
    if (previewExt.indexOf(extension) >= 0) {
      return true;
    }

    return false;
  };

  return { getContentUrl, getThumbnailUrl, getCargoContentButtons, hasPreview };
}

export default createContainer(useMaterial);
