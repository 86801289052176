import React, { useState } from 'react';
import i18next from 'i18next';
import CargoIcon from '../Common/CargoIcon';
import $t from '../../translations/translation';

const NavigationItemLang = () => {
  const [showLangs, setShowLangs] = useState(false);

  const moreLangs = () => {
    setShowLangs(!showLangs);
  };

  const changeLang = lang => {
    i18next.changeLanguage(lang);
    moreLangs();
    localStorage.setItem('language', lang);
    window.location.href = '/app';
  };

  return (
    <li className="cargo-menu-item cargo-menu-item--lang">
      <button
        onClick={() => moreLangs()}
        className="cargo-menu-item__text cargo-font-size-14-16 cargo-font-weight-900"
        type="button">
        {$t('common.language')}
        <CargoIcon
          iconName="arrow-drop-down"
          className={`cargo-menu-item__icon ${!showLangs ? '' : 'is-rotate'}`}
        />
      </button>

      <ul
        className={`cargo-menu__langs cargo-font-size-14-16 cargo-font-weight-600 ${
          showLangs ? 'is-visible' : ''
        }`}>
        <li className="cargo-menu__lang">
          <button
            onClick={() => {
              changeLang('pl');
            }}
            type="button">
            PL
          </button>
        </li>
        <li className="cargo-menu__lang">
          <button
            onClick={() => {
              changeLang('en');
            }}
            type="button">
            EN
          </button>
        </li>
        <li className="cargo-menu__lang">
          <button
            onClick={() => {
              changeLang('ru');
            }}
            type="button">
            RU
          </button>
        </li>
      </ul>
    </li>
  );
};

export default NavigationItemLang;
