import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ handleClick }) => (
  <button onClick={handleClick} className="cargo-hamburger" type="button">
    <span className="cargo-hamburger__line" />
    <span className="cargo-hamburger__line" />
    <span className="cargo-hamburger__line" />
  </button>
);

Hamburger.propTypes = {
  handleClick: PropTypes.func,
};

export default Hamburger;
