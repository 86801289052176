import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from './pl/common.json';
import en from './en/common.json';
import ru from './ru/common.json';

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'pl',
  lng: localStorage.getItem('language') || 'pl',
  resources: {
    pl: {
      translation: pl,
    },
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  },
});

export default i18n;
