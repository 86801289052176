import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// import SearchContext from 'pages/Auth/search.context';
import CategoriesContext from 'contexts/categories.context';
import CargoIcon from '../Common/CargoIcon';
import $t from '../../translations/translation';

const Search = props => {
  const { placeholderText, className, stateHandleClick } = props;

  const InputRef = useRef();
  // const SearchFuntions = SearchContext.useContainer();
  const { search, fetch } = CategoriesContext.useContainer();

  const searchMethod = number => {
    if (InputRef.current.value.length === 0) {
      fetch();
    } else {
      search(InputRef.current.value, number);
      // searchData(InputRef.current.value, number)
      //   .then(result => {
      //     SearchFuntions.searchVal(result.data.result);
      //   })
      //   .then(() => SearchFuntions.searcherName(InputRef.current.value))
      //   .then(() => history.push('/app/search'));
    }
  };
  return (
    <div className={`cargo-search ${className}`}>
      <div className="cargo-search__container">
        <div className="cargo-search__input-container">
          <input
            type="text"
            maxLength="100"
            className="cargo-search__input cargo-font-size-14-16"
            onKeyDown={e => (e.key === 'Enter' ? searchMethod(1) : '')}
            placeholder={$t(placeholderText)}
            ref={InputRef}
          />
          <button
            className="cargo-search__button"
            type="button"
            onClick={() => searchMethod(1)}>
            <CargoIcon iconName="search" className="cargo-search__icon" />
          </button>
        </div>
      </div>

      <button
        onClick={stateHandleClick}
        className="cargo-search__button-mobile"
        type="button">
        <CargoIcon iconName="search" className="cargo-search__icon-mobile" />
      </button>
    </div>
  );
};

Search.propTypes = {
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  stateHandleClick: PropTypes.func,
};

Search.defaultProps = {
  className: '',
};

export default Search;
