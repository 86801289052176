import axios from 'axios';

// function buildFormData(formData, data, parentKey) {
//   if (
//     data &&
//     typeof data === 'object' &&
//     !(data instanceof Date) &&
//     !(data instanceof File)
//   ) {
//     Object.keys(data).forEach(key => {
//       buildFormData(
//         formData,
//         data[key],
//         parentKey ? `${parentKey}[${key}]` : key,
//       );
//     });
//   } else {
//     const value = data == null ? '' : data;

//     formData.append(parentKey, value);
//   }
// }

// const transformUserData = (userData = {}) => {
//   if (userData.bg_instance) {
//     const params = new FormData();
//     Object.keys(userData).forEach(key => {
//       params.append(key, userData[key]);
//     });
//     return params;
//   }
//   const params = new URLSearchParams();
//   buildFormData(params, userData);
//   return params;
// };

class AxiosAdapter {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      // transformRequest: [transformUserData],
    });
  }

  get config() {
    return this.axiosInstance;
  }

  get(url, params) {
    return this.axiosInstance.get(url, { params });
  }

  post(url, payload) {
    return this.axiosInstance.post(url, payload);
  }

  put(url, payload) {
    return this.axiosInstance.put(url, payload);
  }

  patch(url, payload) {
    return this.axiosInstance.patch(url, payload);
  }

  delete(url, id, params) {
    return this.axiosInstance.delete(`${url}/${id}`, { params });
  }
}

export default AxiosAdapter;
