import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

/* _webpackChunkName: "async_page" */

const AsyncPage = page =>
  loadable(() => import(`../${page}`), {
    ssr: false,
    fallback: <></>,
  });

AsyncPage.propTypes = {
  page: PropTypes.string,
};

export default AsyncPage;
