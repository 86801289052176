import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import MaterialContext from 'contexts/material.context';
import Async from './_helpers/AsyncPage';
import Navigation from '../components/Menu/Navigation';
import MainFooter from '../components/MainFooter';
import PrivateRoute from './_helpers/PrivateRoute';

const { Content } = Layout;

function PrivatePages() {
  return (
    <Switch>
      <Route exact path="/app" component={Async('Home')} />
      <Route
        exact
        path="/app/category/:id"
        component={Async('CategoryContent')}
      />
      <Route exact path="/app/content/:id" component={Async('Content')} />
      <Route component={Async('Error/404')} />
    </Switch>
  );
}

const Routes = () => {
  return (
    <Layout>
      <BrowserRouter>
        <Navigation />

        <Content className="cargo__main">
          <Switch>
            <Route exact path="/token/:slug" component={Async('Login')} />
            <PrivateRoute path="/app" redirectTo="/error">
              <MaterialContext.Provider>
                <PrivatePages />
              </MaterialContext.Provider>
            </PrivateRoute>
            <Route exact path="/" component={Async('Error/ErrorLogin')} />
            <Route exact path="/error" component={Async('Error/ErrorLogin')} />
            <Route component={Async('Error/404')} />
          </Switch>
        </Content>

        <MainFooter />
      </BrowserRouter>
    </Layout>
  );
};

export default Routes;
