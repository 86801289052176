import React from 'react';
import { Link } from 'react-router-dom';
import $t from '../translations/translation';

const MainFooter = () => (
  <footer className="cargo-footer">
    <article className="cargo-footer__container cargo-font-size-16-20 cargo-font-weight-700">
      <p className="cargo-footer__text">{$t('common.legal_notice')}</p>
      <p className="cargo-footer__text">
        <Link to="/regulamin">{$t('common.regulations')}</Link>
      </p>
    </article>
  </footer>
);

export default MainFooter;
