import React from 'react';
import PropTypes from 'prop-types';

const CargoIcon = ({ iconName, className }) => (
  <span className={`icon-${iconName} ${className}`} />
);

CargoIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CargoIcon.defaultProps = {
  className: '',
};

export default CargoIcon;
