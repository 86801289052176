import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import AuthContext from '../Auth/auth.context';

const PrivateRoute = React.memo(({ children, redirectTo }) => {
  const { auth } = AuthContext.useContainer();
  const location = useLocation();

  return auth.isAuthenticated ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: redirectTo,
        state: { referer: location },
      }}
    />
  );
});

PrivateRoute.propTypes = {
  children: PropTypes.node,
  redirectTo: PropTypes.string,
};

export default PrivateRoute;
