import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { API } from 'services';

const initialState = localStorage.getItem('auth')
  ? JSON.parse(localStorage.getItem('auth'))
  : {
      user: {
        displayName: '',
        email: '',
        photoURL: '',
      },
      isAuthenticated: false,
    };

const initialToken = localStorage.getItem('token')
  ? JSON.parse(localStorage.getItem('token'))
  : {
      token: '',
      refresh: '',
    };

function useAuth() {
  const [auth, setAuth] = useState(initialState);
  const [token, setToken] = useState(() => {
    API.setHeaders(initialToken.token);
    return initialToken;
  });

  const refresh = useCallback(userData => {
    const refreshData = {
      token: userData.result.token,
      refresh: userData.result.refresh,
    };
    setToken(refreshData);
    localStorage.setItem('token', JSON.stringify(refreshData));
  }, []);

  const login = useCallback(userData => {
    const authData = {
      isAuthenticated: true,
      user: userData.user,
    };
    const authToken = {
      token: userData.token,
      refresh: userData.refresh,
    };
    API.setHeaders(authToken.token);

    setAuth(authData);
    setToken(authToken);
    localStorage.setItem('auth', JSON.stringify(authData));
    localStorage.setItem('token', JSON.stringify(authToken));
  }, []);

  const logout = useCallback(() => {
    setAuth({
      isAuthenticated: false,
    });
    API.logout().then(() => {
      localStorage.removeItem('auth');
      localStorage.removeItem('token');
      window.location = 'https://e-cargo.edu.pl/login/logout';
    });
  }, []);

  return { login, logout, refresh, auth, token };
}

export default createContainer(useAuth);
