import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CargoIcon from '../Common/CargoIcon';
import $t from '../../translations/translation';

const NavigationItem = ({ text, iconName, typeEl, handleClick, link }) => (
  <li className="cargo-menu-item">
    {typeEl === 'link' ? (
      <Link to={link} className="cargo-menu-item__text cargo-font-size-14">
        <CargoIcon iconName={iconName} className="cargo-menu-item__icon" />
        {$t(text)}
      </Link>
    ) : (
      <button
        onClick={handleClick}
        className="cargo-menu-item__text cargo-font-size-14"
        type="button">
        <CargoIcon iconName={iconName} className="cargo-menu-item__icon" />
        {$t(text)}
      </button>
    )}
  </li>
);

NavigationItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,
  typeEl: PropTypes.string,
  handleClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  iconName: '',
  typeEl: 'link',
};

export default NavigationItem;
