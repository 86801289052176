// import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { useCallback, useState } from 'react';
import { API } from 'services';

function useCategories() {
  const { fetchCategories, fetchMaterials, searchData } = API;

  const [loaded, setLoaded] = useState(false);
  const [coreCategories, setCoreCategories] = useState();
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [searchStatus, setSearchStatus] = useState({
    status: false,
    searching: false,
    last_page: 0,
    current_page: 0,
    phrase: null,
  });

  const listToTree = (items, parent = null) => {
    const nested = [];

    Object.values(items).forEach(item => {
      /* eslint-disable-next-line eqeqeq */
      if (item.parent_id == parent) {
        const subcategories = listToTree(items, item.id);

        if (subcategories.length) {
          /* eslint-disable-next-line no-param-reassign */
          item.subcategories = subcategories;
        }

        nested.push(item);
      }
    });

    return nested;
  };

  const merge = (_categories, _materials) => {
    _materials.sort((a, b) => {
      if (a.is_promoted === b.is_promoted) {
        return b.created_at - a.created_at;
      }
      if (a.is_promoted > b.is_promoted) {
        return -1;
      }
      if (a.is_promoted < b.is_promoted) {
        return 1;
      }
      return null;
    });
    _materials.forEach(element => {
      const categoryId = element.category_id;
      const catItem = _categories.find(cat => cat.id === categoryId);
      if (catItem) catItem.materials.push(element);
    });

    const list = listToTree(_categories);
    setCategories(list);
    setMaterials(_materials);
  };

  const fetch = useCallback(async () => {
    setSearchStatus({
      status: false,
      searching: false,
      last_page: 0,
      current_page: 0,
      phrase: null,
    });
    const categoriesRequest = await fetchCategories().then(({ result }) => {
      return result.categories.map(item => ({
        ...item,
        materials: [],
        subcategories: [],
      }));
    });

    const materialsRequest = await fetchMaterials().then(({ result }) => {
      setLoaded(true);
      return result.materials;
    });

    const [cat, _materials] = await Promise.all([
      categoriesRequest,
      materialsRequest,
    ]);

    const coreCats = JSON.parse(JSON.stringify(cat));
    setCoreCategories(coreCats);
    merge(cat, _materials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (phrase, number) => {
    setSearchStatus({
      status: false,
      searching: true,
      last_page: 0,
      current_page: 0,
      phrase: null,
    });
    searchData(phrase, number).then(result => {
      const cat = JSON.parse(JSON.stringify(coreCategories));
      merge(cat, result.data.result.materials);
      setSearchStatus({
        status: true,
        searching: false,
        last_page: result.data.result.last_page,
        current_page: result.data.result.current_page,
        phrase,
      });
    });
  };

  const clearSearch = () => {
    searchStatus.status = false;
    searchStatus.searching = false;
  };

  const setSearchStatusTrue = () => {
    setSearchStatus({
      status: true,
      searching: searchStatus.searching,
      last_page: searchStatus.last_page,
      current_page: searchStatus.current_page,
      phrase: searchStatus.phrase,
    });
  };

  const findById = (tree, nodeId) => {
    if (tree === undefined) {
      return undefined;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const node of tree) {
      // eslint-disable-next-line eqeqeq
      if (node.id == nodeId) return node;

      if (node.subcategories) {
        const desiredNode = findById(node.subcategories, nodeId);
        if (desiredNode) return desiredNode;
      }
    }
    return undefined;
  };

  const getCategory = id => {
    // eslint-disable-next-line eqeqeq
    return findById(categories, id);
  };

  const getMaterial = id => {
    // eslint-disable-next-line eqeqeq
    return materials.find(m => m.id == id);
  };

  return {
    fetch,
    categories,
    search,
    searchStatus,
    materials,
    clearSearch,
    getMaterial,
    getCategory,
    setSearchStatusTrue,
    loaded,
  };
}

export default createContainer(useCategories);
