export default class API {
  constructor(adapter) {
    this.api = adapter;

    this.api.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error.hasRefreshedToken ||
          error.response.status !== 401 ||
          error.response.config.url === 'auth/login'
        ) {
          return Promise.reject(error);
        }

        const initialToken = localStorage.getItem('token')
          ? JSON.parse(localStorage.getItem('token'))
          : {
              token: '',
              refresh: '',
            };
        const hasRefreshed = error;
        hasRefreshed.hasRefreshedToken = true;

        return this.refreshToken(initialToken.refresh).then(res => {
          const { config } = error.response;
          config.headers.Authorization = `Bearer ${res.data.result.token}`;
          this.setHeaders(res.data.result.token);
          localStorage.setItem('token', JSON.stringify(res.data.result));
          return this.api.config(error.response.config);
        });
      },
    );
  }

  setHeaders = token => {
    if (token) {
      this.api.axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
    }
  };

  login = token => {
    let tokenTmp = token.replace('.', '/');
    tokenTmp = token.replace('_', '+');
    return this.api.post('auth/login', {
      token: atob(tokenTmp).trim(),
    });
  };

  logout = token => {
    return this.api.post('auth/logout', {
      token,
    });
  };

  refreshToken = token => {
    return this.api.post('auth/refresh', {
      refresh_token: token,
    });
  };

  searchData = async (search, page) => {
    return this.api.post('material/search', {
      search,
      page,
    });
  };

  checkToken = () => {
    this.api.get('auth/ping').then(res => {
      return res;
    });
  };

  fetchCategories = () => this.api.get('categories').then(res => res.data);

  fetchMaterials = () => this.api.get('material').then(res => res.data);
}
