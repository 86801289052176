import React from 'react';
import PropTypes from 'prop-types';
import $t from '../../translations/translation';

const CargoContentButton = props => {
  const { url, downloadUrl } = props;
  return (
    <>
      {downloadUrl !== '' && (
        <a
          download
          href={`${downloadUrl}`}
          className="cargo-button cargo-content__button cargo-button--content">
          {$t('common.download_material')}
        </a>
      )}
      {url !== '' && (
        <a
          href={`${url}`}
          className="cargo-button cargo-content__button cargo-button--content">
          {$t('common.view_file')}
        </a>
      )}
    </>
  );
};

CargoContentButton.propTypes = {
  url: PropTypes.string,
  downloadUrl: PropTypes.string,
};

export default CargoContentButton;
