import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from 'pages/Auth/auth.context';
import NavigationItem from './NavigationItem';
import Hamburger from './Hamburger';
import NavigationItemLang from './NavigationItemLang';
import Search from './Search';
import { ReactComponent as Logo } from '../../logo.svg';

const Navigation = () => {
  const AuthFunctions = AuthContext.useContainer();
  const [state, setState] = useState({
    menuState: false,
    searchState: false,
    searchValue: null,
  });

  const isLoggedIn = !!AuthFunctions.token.token;

  const changeMenuState = () => {
    setState({
      menuState: !state.menuState,
    });
  };

  const changeSearchState = () => {
    setState({
      searchState: !state.searchState,
    });
  };

  const searchQuery = e => {
    setState({
      searchValue: { e },
    });
  };

  const gotoHome = () => {
    window.location.href = '/app';
  };

  const gotoTutorials = () => {
    window.location.href = 'https://e-cargo.edu.pl';
  };

  return (
    <nav className={`cargo-menu ${state.menuState ? 'is-active' : ''}`}>
      <h1 className="cargo-menu__logo">
        <Link to="/app" onClick={gotoHome}>
          <Logo />
        </Link>
      </h1>

      <Search
        className={state.searchState ? 'is-active' : ''}
        placeholderText="common.search_placeholder"
        stateHandleClick={changeSearchState}
        handleClick={searchQuery}
      />

      <ul className="cargo-menu__list">
        <NavigationItem
          typeEl="none"
          iconName="file-copy"
          link="/"
          text="common.tutorials"
          handleClick={gotoTutorials}
        />
        <NavigationItem
          typeEl="none"
          iconName="dashboard"
          link="/app"
          text="common.knowledge_base"
          handleClick={gotoHome}
        />
        {isLoggedIn && (
          <NavigationItem
            iconName="exit-to-app"
            typeEl="button"
            handleClick={() => AuthFunctions.logout()}
            text="common.logout"
          />
        )}

        <NavigationItemLang />
      </ul>

      <Hamburger handleClick={changeMenuState} />
    </nav>
  );
};

export default Navigation;
