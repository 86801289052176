import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

const initialSearch = localStorage.getItem('search')
  ? JSON.parse(localStorage.getItem('search'))
  : {
      materials: [],
      last_page: '',
      current_page: '',
    };

const initialSearcher = localStorage.getItem('searcher')
  ? JSON.parse(localStorage.getItem('searcher'))
  : '';

function useSearch() {
  const [value, setValue] = useState(initialSearch);
  const [searcher, setSearcher] = useState(initialSearcher);

  const searchVal = useCallback(searchValue => {
    const searchData = searchValue;
    setValue({
      materials: searchValue.materials,
      last_page: searchValue.last_page,
      current_page: searchValue.current_page,
    });
    localStorage.setItem('search', JSON.stringify(searchData));
  }, []);

  const searcherName = useCallback(searchValue => {
    const searchData = searchValue;
    setSearcher(searchData);
    localStorage.setItem('searcher', JSON.stringify(searchData));
  }, []);

  return { value, searchVal, searcherName, searcher };
}

export default createContainer(useSearch);
