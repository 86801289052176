import API_URL from 'services/API_URL';
import ApiService from './api';
import Axios from './api/adapters/AxiosAdapter';

const language = localStorage.getItem('language');

const ApiLanguage = () => {
  if (language === 'en') {
    return `${API_URL}/api/en/`;
  }
  if (language === 'ru') {
    return `${API_URL}/api/ru/`;
  }
  return `${API_URL}/api/pl/`;
};

const axiosInstance = new Axios(ApiLanguage());

export const API = new ApiService(axiosInstance);

export default {
  API,
};
